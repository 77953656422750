export const skillsData = [
  { title: "Acrobatics", skillName: "acrobatics" },
  { title: "Animal Handling", skillName: "animalHandling" },
  { title: "Arcana", skillName: "arcana" },
  { title: "Athletics", skillName: "athletics" },
  { title: "Deception", skillName: "deception" },
  { title: "History", skillName: "history" },
  { title: "Insight", skillName: "insight" },
  { title: "Intimidation", skillName: "intimidation" },
  { title: "Investigation", skillName: "investigation" },
  { title: "Medicine", skillName: "medicine" },
  { title: "Nature", skillName: "nature" },
  { title: "Perception", skillName: "perception" },
  { title: "Performance", skillName: "performance" },
  { title: "Persuasion", skillName: "persuasion" },
  { title: "Religion", skillName: "religion" },
  { title: "Sleight of Hand", skillName: "sleightOfHand" },
  { title: "Stealth", skillName: "stealth" },
  { title: "Survival", skillName: "survival" },
];
