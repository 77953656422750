export const characterFormData = [
    { type: "text", field: "name", headerName: "Name" },
    { type: "text", field: "characterClass", headerName: "Character Class" },
    { type: "text", field: "race", headerName: "Race" },
    { type: "text", field: "background", headerName: "Background" },
    { type: "text", field: "alignment", headerName: "Alignment" },
    { type: "text", field: "languagesKnown", headerName: "Languages Known" },
    {
      type: "text",
      field: "armorProficiences",
      headerName: "Armor Proficiencies",
    },
    {
      type: "text",
      field: "weaponProficiences",
      headerName: "Weapon Proficiencies",
    },
    { type: "text", field: "toolProficiences", headerName: "Tool Proficiencies" },
    { type: "number", field: "hpMax", headerName: "HP Max" },
    { type: "number", field: "str", headerName: "Strength" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "str",
      headerName: "Saving Throw Proficiency (STR)",
    },
    { type: "number", field: "int", headerName: "Intelligence" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "int",
      headerName: "Saving Throw Proficiency (INT)",
    },
    { type: "number", field: "dex", headerName: "Dexterity" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "dex",
      headerName: "Saving Throw Proficiency (DEX)",
    },
    { type: "number", field: "wis", headerName: "Wisdom" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "wis",
      headerName: "Saving Throw Proficiency (WIS)",
    },
    { type: "number", field: "con", headerName: "Constitution" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "con",
      headerName: "Saving Throw Proficiency (CON)",
    },
    { type: "number", field: "char", headerName: "Charisma" },
    {
      type: "checkbox",
      sheetType: "savingThrow",
      field: "char",
      headerName: "Saving Throw Proficiency (CHA)",
    },
    { type: "number", field: "ac", headerName: "AC" },
    { type: "text", field: "speed", headerName: "Speed" },
    { type: "number", field: "level", headerName: "Total Level" },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "athletics",
      headerName: "Athletics",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "acrobatics",
      headerName: "Acrobatics",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "sleightOfHand",
      headerName: "Sleight Of Hand",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "stealth",
      headerName: "Stealth",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "arcana",
      headerName: "Arcana",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "history",
      headerName: "History",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "investigation",
      headerName: "Investigation",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "nature",
      headerName: "Nature",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "religion",
      headerName: "Religion",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "animalHandling",
      headerName: "Animal Handling",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "insight",
      headerName: "Insight",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "medicine",
      headerName: "Medicine",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "perception",
      headerName: "Perception",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "survival",
      headerName: "Survival",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "deception",
      headerName: "Deception",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "intimidation",
      headerName: "Intimidation",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "performance",
      headerName: "Performance",
    },
    {
      type: "checkbox",
      sheetType: "skills",
      field: "persuasion",
      headerName: "Persuasion",
    },
  ];
